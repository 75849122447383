<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import complex from '../../../../compoment/complex/complex';

formCreate.component('complex', complex);
let formData;
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
    };
  },

  mounted() {
    this.getFormRule('capital-pool-typeB-form');
  },

  methods: {
    setRule(v) {
      const rowData = v;
      if (rowData.field === 'accountFee') {
        rowData.on = {
          ...rowData.on,
          input: (value) => {
            const balance = this.getFieldValue('balance');
            const sourceType = this.getFieldValue('sourceType');
            this.setValue({ h: (sourceType === '2' || sourceType === '9') ? balance + value : balance - value });
          },
        };
      }
      if (rowData.field === 'sourceType') {
        rowData.on = {
          ...rowData.on,
          change: () => {
            this.setValue({ accountFee: '' });
          },
        };
      }
      if (rowData.field === 'fileVos') {
        rowData.props.fileMsg = '请上传pdf/word/excel/ppt/png/jpg格式的文件';
        rowData.props.accept = 'pdf,word,xlsx,ppt,png,jpg';
      }
      return rowData;
    },

    formComplete() {
      if (this.formConfig.poolId) {
        this.getData(this.formConfig.poolId);
      }
    },

    async getData(id) {
      const { result } = await this.$http.post(
        '/dms/bCaptitalPoolController/query', { id },
      );
      formData = result;
      this.setValue({
        ...result,
      });
    },

    submit() {
      let params = this.getFormData();
      const path = '/dms/bCaptitalPoolDetailController/save';
      params.poolId = this.formConfig.poolId;
      params = { ...params, ...params.effective };
      if (params.sourceType === '9' && params.accountFee > formData.frozenFee) {
        this.$message.error('调整金额不能大于冻结金额');
        return;
      }
      request.post(path, params)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success('操作成功！');
            this.$emit('onClose');
            this.$emit('onGetList');
          } else {
            this.$message.error(res.messge);
          }
        });
    },
  },
};
</script>
